import { createTheme } from '@mui/material/styles';

// Theme configuration based on Studyfy design system
const theme = (mode) => {
  const isDark = mode === 'dark';
  
  return createTheme({
    palette: {
      mode,
      primary: {
        main: isDark ? '#9382FA' : '#513EEB',
        light: isDark ? '#B4A9FA' : '#6A5AE0',
        dark: isDark ? '#7B6AF8' : '#4232C2',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: isDark ? '#B4A9FA' : '#6A5AE0',
        light: isDark ? '#C6BEFA' : '#8070E6',
        dark: isDark ? '#A295F9' : '#5649C0',
        contrastText: '#FFFFFF',
      },
      background: {
        default: isDark ? '#121212' : '#FFFFFF',
        paper: isDark ? '#1E1E1E' : '#FFFFFF',
        secondary: isDark ? '#1E1E1E' : '#F8F9FA',
        tertiary: isDark ? '#303030' : '#F1F3F5',
      },
      text: {
        primary: isDark ? '#FFFFFF' : '#212529',
        secondary: isDark ? '#E0E0E0' : '#495057',
        tertiary: isDark ? '#BDBDBD' : '#868E96',
        disabled: isDark ? '#757575' : '#ADB5BD',
      },
      error: {
        main: isDark ? '#EF5350' : '#E53935',
      },
      warning: {
        main: isDark ? '#FFB74D' : '#FF9800',
      },
      info: {
        main: isDark ? '#29B6F6' : '#0288D1',
      },
      success: {
        main: isDark ? '#4CAF50' : '#2E7D32',
      },
      divider: isDark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
    },
    typography: {
      fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '32px',
        letterSpacing: '-0.5px',
      },
      h2: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
        fontSize: '28px',
        letterSpacing: '-0.5px',
      },
      h3: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '24px',
        letterSpacing: '-0.25px',
      },
      h4: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '22px',
        letterSpacing: '-0.25px',
      },
      h5: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '20px',
        letterSpacing: '-0.25px',
      },
      h6: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '18px',
        letterSpacing: '-0.25px',
      },
      subtitle1: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '16px',
        letterSpacing: '0px',
      },
      subtitle2: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        letterSpacing: '0px',
      },
      body1: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '16px',
        lineHeight: 1.5,
        letterSpacing: '0.25px',
      },
      body2: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '14px',
        lineHeight: 1.5,
        letterSpacing: '0.25px',
      },
      button: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        letterSpacing: '0.1px',
        textTransform: 'none',
      },
      caption: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '12px',
        lineHeight: 1.4,
        letterSpacing: '0.25px',
      },
      overline: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
      },
    },
    shape: {
      borderRadius: 8,
    },
    spacing: 8,
    shadows: [
      'none',
      '0px 2px 4px rgba(0, 0, 0, 0.05)', // Low shadow
      '0px 4px 8px rgba(0, 0, 0, 0.1)',  // Medium shadow
      '0px 8px 16px rgba(0, 0, 0, 0.15)', // High shadow
      // ...rest of MUI shadows with slight adjustments
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
      '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    ],
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: 'none',
            fontWeight: 600,
            padding: '8px 16px',
          },
          contained: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
            '&:hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
          },
        },
      },
    },
  });
};

export default theme; 