import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Box, 
  Button,
  TextField,
  Typography, 
  Paper, 
  Container,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Alert,
  Checkbox,
  FormControlLabel,
  Stack
} from '@mui/material';
import { Google as GoogleIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import AppleIcon from '@mui/icons-material/Apple';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../../components/common/Logo';

const Signup = () => {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const navigate = useNavigate();
  const { signup, loginWithGoogle, loginWithApple } = useAuth();

  const validatePassword = (password) => {
    return password.length >= 6;
  };
  
  const handleEmailSignup = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (!displayName || !email || !password || !confirmPassword) {
      setError('Por favor, preencha todos os campos.');
      return;
    }
    
    if (!termsAccepted) {
      setError('Você precisa aceitar os termos de uso para continuar.');
      return;
    }

    if (password !== confirmPassword) {
      setError('As senhas não conferem.');
      return;
    }
    
    if (!validatePassword(password)) {
      setError('A senha deve ter pelo menos 6 caracteres.');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      await signup(email, password, displayName);
      setSuccess('Conta criada com sucesso! Verifique seu email para confirmar seu cadastro.');
      
      // Redirect after a brief delay to show success message
      setTimeout(() => {
        navigate('/auth/login');
      }, 3000);
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError('Este email já está em uso. Por favor, tente outro.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Email inválido. Por favor, verifique e tente novamente.');
      } else if (err.code === 'auth/weak-password') {
        setError('Sua senha é muito fraca. Por favor, escolha uma senha mais forte.');
      } else {
        setError('Falha ao criar a conta. Por favor, tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    if (!termsAccepted) {
      setError('Você precisa aceitar os termos de uso para continuar.');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      navigate('/dashboard');
    } catch (err) {
      setError('Falha ao fazer login com Google. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAppleSignup = async () => {
    if (!termsAccepted) {
      setError('Você precisa aceitar os termos de uso para continuar.');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      await loginWithApple();
      navigate('/dashboard');
    } catch (err) {
      setError('Falha ao fazer login com Apple. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: 2,
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Logo />
          </Box>
          
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Crie sua conta
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Junte-se a milhares de estudantes
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ width: '100%', mb: 3 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ width: '100%', mb: 3 }}>
              {success}
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleEmailSignup} sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="displayName"
              label="Nome completo"
              name="displayName"
              autoComplete="name"
              autoFocus
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
              helperText="A senha deve ter pelo menos 6 caracteres"
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirmar senha"
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
              sx={{ mb: 2 }}
            />
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                  disabled={loading}
                />
              }
              label={
                <Typography variant="body2" color="text.secondary">
                  Eu concordo com os{' '}
                  <Link to="/terms" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                      Termos de Uso
                    </Typography>
                  </Link>
                  {' '}e{' '}
                  <Link to="/privacy" style={{ textDecoration: 'none' }}>
                    <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                      Política de Privacidade
                    </Typography>
                  </Link>
                </Typography>
              }
              sx={{ mb: 2 }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ py: 1.5, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Criar conta'}
            </Button>
            
            <Divider sx={{ my: 3 }}>ou</Divider>
            
            <Stack spacing={2}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSignup}
                disabled={loading}
                sx={{ py: 1.5 }}
              >
                Cadastrar com Google
              </Button>
              
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AppleIcon />}
                onClick={handleAppleSignup}
                disabled={loading}
                sx={{ 
                  py: 1.5, 
                  mb: 3,
                  bgcolor: 'black',
                  color: 'white',
                  borderColor: 'black',
                  '&:hover': {
                    bgcolor: '#333',
                    borderColor: '#333',
                  }
                }}
              >
                Cadastrar com Apple
              </Button>
            </Stack>
            
            <Box sx={{ py: 2, textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                Já tem uma conta?{' '}
                <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                  <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                    Entre agora
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Signup; 