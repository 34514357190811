import { getToken, onMessage } from "firebase/messaging";
import { messaging, db } from "../config/firebase"; // Assuming messaging is exported from your config
import { doc, setDoc } from "firebase/firestore";

// Function to request notification permission
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.log("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Function to get the FCM token
export const getFCMToken = async (vapidKey) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return null;
  }
  if (!vapidKey) {
    console.error("VAPID key is required to get FCM token.");
    return null;
  }

  try {
    const currentToken = await getToken(messaging, { vapidKey: vapidKey });
    if (currentToken) {
      console.log("FCM Token:", currentToken);
      return currentToken;
    } else {
      console.log("No registration token available. Request permission first.");
      // Optional: Request permission if not granted
      // const granted = await requestNotificationPermission();
      // if (granted) return getFCMToken(vapidKey); // Retry getting token
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
    return null;
  }
};

// Function to save the FCM token to Firestore for a user
export const saveTokenToFirestore = async (userId, token) => {
  if (!userId || !token) {
    console.error("User ID and token are required to save token.");
    return;
  }
  try {
    const userRef = doc(db, "users", userId);
    // Store the token in a subcollection or directly on the user document
    // Using a subcollection 'fcmTokens' allows multiple tokens per user (e.g., different browsers/devices)
    const tokenRef = doc(db, `users/${userId}/fcmTokens`, token); // Using token as doc ID for uniqueness
    await setDoc(tokenRef, { 
        token: token,
        createdAt: new Date() // Store creation timestamp
     });
    console.log("FCM token saved for user:", userId);
  } catch (error) {
    console.error("Error saving FCM token to Firestore:", error);
  }
};

// Function to setup foreground message handling
export const onForegroundMessage = (callback) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return null;
  }
  return onMessage(messaging, (payload) => {
    console.log("Message received in foreground: ", payload);
    // Trigger the callback with the payload
    // The app can then decide how to display this (e.g., a toast notification)
    if (callback) {
      callback(payload);
    }
  });
}; 