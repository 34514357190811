import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  themeMode: 'light', // 'light', 'dark'
  sidebarOpen: true,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { toggleThemeMode, setThemeMode, toggleSidebar, setSidebarOpen } = uiSlice.actions;

// Selectors
export const selectThemeMode = (state) => state.ui.themeMode;
export const selectSidebarOpen = (state) => state.ui.sidebarOpen;

export default uiSlice.reducer; 