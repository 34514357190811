import React from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardActions, 
  Typography, 
  Button, 
  LinearProgress, 
  Chip,
  Grid,
  Tooltip
} from '@mui/material';
import { 
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  ArrowForward as ArrowForwardIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
}));

const StudyPlanCard = ({ plan }) => {
  const { title, description, progress, startDate, endDate, topics = [] } = plan;
  
  // Format the dates
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('pt-BR', { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    });
  };
  
  return (
    <StyledCard>
      <CardContent sx={{ flex: '1 0 auto', p: 3 }}>
        <Typography 
          variant="h6" 
          component="div" 
          gutterBottom
          sx={{ 
            fontWeight: 'bold', 
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {title}
        </Typography>
        
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            mb: 2,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: '40px'
          }}
        >
          {description}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Tooltip title="Data de início">
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
              <CalendarTodayIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
              <Typography variant="caption" color="text.secondary">
                {formatDate(startDate)}
              </Typography>
            </Box>
          </Tooltip>
          
          <Tooltip title="Data de término">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
              <Typography variant="caption" color="text.secondary">
                {formatDate(endDate)}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
            <Typography variant="body2">Progresso</Typography>
            <Typography variant="body2" color="text.secondary">{progress}%</Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ height: 6, borderRadius: 3 }} 
          />
        </Box>
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {topics.slice(0, 3).map((topic, index) => (
            <Chip 
              key={index} 
              label={topic} 
              size="small" 
              color={index === 0 ? 'primary' : 'default'} 
              variant={index === 0 ? 'filled' : 'outlined'} 
            />
          ))}
          {topics.length > 3 && (
            <Chip 
              label={`+${topics.length - 3}`} 
              size="small" 
              variant="outlined" 
            />
          )}
        </Box>
      </CardContent>
      
      <CardActions sx={{ p: 2, pt: 0, mt: 'auto' }}>
        <Button 
          size="small" 
          endIcon={<ArrowForwardIcon />}
          sx={{ ml: 'auto' }}
        >
          Ver plano
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default StudyPlanCard; 