import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
// Import notification service functions
import { 
  requestNotificationPermission, 
  getFCMToken, 
  saveTokenToFirestore,
  onForegroundMessage
} from '../services/notificationService';

// Create the authentication context
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Auth Provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState(null);
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false); // Optional state for permission

  // Register a new user
  const signup = async (email, password, displayName) => {
    try {
      setError('');
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Update profile with display name
      await updateProfile(result.user, { displayName });
      
      // Send email verification
      const actionCodeSettings = {
        url: 'https://studyfy.com.br/auth/action',
        handleCodeInApp: false
      };
      await sendEmailVerification(result.user, actionCodeSettings);
      
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Login with email and password
  const login = async (email, password) => {
    try {
      setError('');
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      // Check if email is verified
      if (!result.user.emailVerified) {
        // Log the user out
        await signOut(auth);
        setVerificationRequired(true);
        setUnverifiedEmail(email);
        throw new Error('email-not-verified');
      }
      
      return result.user;
    } catch (err) {
      if (err.message === 'email-not-verified') {
        setError('Por favor, verifique seu email antes de fazer login. Enviamos um link de verificação para seu email.');
      } else if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        setError('Email ou senha incorretos. Por favor, tente novamente.');
      } else {
        setError(err.message);
      }
      throw err;
    }
  };

  // Login with Google
  const loginWithGoogle = async () => {
    try {
      setError('');
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Check if user document exists in Firestore
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      
      // Create user document if it doesn't exist
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', result.user.uid), {
          displayName: result.user.displayName,
          email: result.user.email,
          createdAt: serverTimestamp(),
          isProfileCompleted: false,
          isEmailVerified: result.user.emailVerified,
          role: 'user',
          isPremium: false
        });
      }
      
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Login with Apple
  const loginWithApple = async () => {
    try {
      setError('');
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      
      const result = await signInWithPopup(auth, provider);
      
      // Check if user document exists in Firestore
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      
      // Create user document if it doesn't exist
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', result.user.uid), {
          displayName: result.user.displayName || 'Usuário Apple', // Apple might not provide the name
          email: result.user.email,
          createdAt: serverTimestamp(),
          isProfileCompleted: false,
          isEmailVerified: result.user.emailVerified,
          role: 'user',
          isPremium: false,
          authProvider: 'apple'
        });
      }
      
      return result.user;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Resend verification email
  const resendVerificationEmail = async () => {
    try {
      setError('');
      if (!auth.currentUser) {
        // Try to sign in temporarily to send verification email
        if (unverifiedEmail) {
          await signInWithEmailAndPassword(auth, unverifiedEmail, '');
        } else {
          throw new Error('No user is currently logged in');
        }
      }
      
      const actionCodeSettings = {
        url: 'https://studyfy.com.br/auth/action',
        handleCodeInApp: false
      };
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      // Sign out after sending verification
      if (unverifiedEmail) {
        await signOut(auth);
      }
      
      return true;
    } catch (err) {
      if (err.code === 'auth/wrong-password') {
        // This is expected since we're trying with empty password
        // We want to access the user object to send verification
        if (auth.currentUser) {
          const actionCodeSettings = {
            url: 'https://studyfy.com.br/auth/action',
            handleCodeInApp: false
          };
          await sendEmailVerification(auth.currentUser, actionCodeSettings);
          await signOut(auth);
          return true;
        }
      }
      setError('Falha ao enviar email de verificação. Por favor, tente novamente mais tarde.');
      throw err;
    }
  };

  // Logout user
  const logout = () => {
    setError('');
    setVerificationRequired(false);
    setUnverifiedEmail(null);
    return signOut(auth);
  };

  // Reset password
  const forgotPassword = (email) => {
    setError('');
    const actionCodeSettings = {
      url: 'https://studyfy.com.br/auth/action',
      handleCodeInApp: false
    };
    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  };

  // Update user profile
  const updateUserProfile = async (data) => {
    try {
      setError('');
      // Update Firebase Auth profile if displayName is provided
      if (data.displayName) {
        await updateProfile(auth.currentUser, { displayName: data.displayName });
      }
      
      // Update user document in Firestore
      await setDoc(doc(db, 'users', auth.currentUser.uid), data, { merge: true });
      
      // Refresh user data
      setCurrentUser({ ...currentUser, ...data });
    } catch (err) {
      setError(err.message);
      throw err;
    }
  };

  // Check if user is complete to display the appropriate screens
  const isUserComplete = async (user) => {
    if (!user) return false;
    
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    return userDoc.exists() && userDoc.data().isProfileCompleted === true;
  };

  // Function to setup notifications for the current user
  const setupNotifications = async (user) => {
    if (!user || !user.uid) return;

    console.log('Attempting to set up notifications for user:', user.uid);
    try {
      const permissionGranted = await requestNotificationPermission();
      setNotificationPermissionGranted(permissionGranted); // Update state

      if (permissionGranted) {
        const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY; 
        if (!vapidKey) {
            console.error("Firebase VAPID key not found in environment variables (REACT_APP_FIREBASE_VAPID_KEY). Cannot get FCM token.");
            return;
        }
        console.log('Notification permission granted. Getting FCM token...');
        const token = await getFCMToken(vapidKey);
        if (token) {
          console.log('FCM token received, saving to Firestore...');
          await saveTokenToFirestore(user.uid, token);
        } else {
          console.log('Could not retrieve FCM token.');
        }
      } else {
        console.log('Notification permission was not granted.');
      }
    } catch (err) {
      console.error('Error setting up notifications:', err);
    }
  };

  // Auth state change listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true); // Set loading true at the start of auth check
      if (user) {
        // Check if email is verified BEFORE fetching Firestore data
        if (!user.emailVerified) {
          console.log('User email not verified. Logging out.');
          await signOut(auth); // Ensure signout happens
          setVerificationRequired(true);
          setUnverifiedEmail(user.email);
          setCurrentUser(null);
          setLoading(false);
          return;
        }
        
        try {
          console.log('User authenticated and email verified. Fetching Firestore data...');
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          let userData = {};
          if (userDoc.exists()) {
            userData = userDoc.data();
             // Update the isEmailVerified status in Firestore if needed
             if (userData.isEmailVerified !== user.emailVerified) {
               console.log('Updating isEmailVerified status in Firestore.');
               await setDoc(doc(db, 'users', user.uid), 
                 { isEmailVerified: user.emailVerified }, 
                 { merge: true }
               );
               userData.isEmailVerified = user.emailVerified; // Update local data too
             }
          } else {
             // If user doc doesn't exist, maybe create a basic one? Or handle accordingly.
             // For now, we'll just use the auth user object.
             console.warn(`Firestore document for user ${user.uid} not found.`);
          }
          
          const fullUser = {
            ...user, // Raw auth user data
            ...userData // Firestore data
          };
          setCurrentUser(fullUser);
          console.log('User data loaded:', fullUser.uid);
          
          // Setup notifications only AFTER user state is fully set
          // Ensure profile completion if necessary for notifications
          // if (fullUser.isProfileCompleted) { // Add check if notifications depend on profile completion
             await setupNotifications(fullUser); 
          // } else {
          //    console.log('User profile not complete, skipping notification setup.');
          // }

        } catch (err) {
          console.error('Error fetching/processing user data:', err);
          setCurrentUser(user); // Fallback to auth user data if Firestore fails
          await setupNotifications(user); // Attempt setup even if Firestore fetch failed
        }
      } else {
        console.log('No user authenticated.');
        setCurrentUser(null);
        setNotificationPermissionGranted(false); // Reset permission state on logout
      }
      setLoading(false); // Set loading false after all checks/fetches
    });

    return unsubscribe;
  }, []); // Keep dependency array empty to run only on mount/unmount

  // Listener for foreground messages (runs once on context mount)
  useEffect(() => {
    const unsubscribe = onForegroundMessage((payload) => {
      console.log('Foreground message received in AuthContext:', payload);
      // TODO: Implement UI feedback for foreground messages 
      // (e.g., show a toast/snackbar notification)
      // Example: showSnackbar(`New message: ${payload.notification.title}`);
       alert(`New Notification: ${payload.notification.title}\\n${payload.notification.body}`); // Simple alert for now
    });

    // Cleanup listener on unmount
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  // Auth context value
  const value = {
    currentUser,
    signup,
    login,
    loginWithGoogle,
    loginWithApple,
    logout,
    forgotPassword,
    updateUserProfile,
    isUserComplete,
    error,
    verificationRequired,
    unverifiedEmail,
    resendVerificationEmail,
    notificationPermissionGranted // Expose permission state if needed by UI
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}; 