import React from 'react';
import { Box, CircularProgress, Typography, ThemeProvider, createTheme } from '@mui/material';

// Default theme for loading screen (before Redux is initialized)
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#513EEB',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
    },
  },
});

const LoadingScreen = ({ message = 'Carregando...' }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          gap: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          sx={{ 
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: 'primary.main',
            mb: 1,
          }}
        >
          Studyfy
        </Typography>
        
        <CircularProgress color="primary" />
        
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
          {message}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default LoadingScreen; 