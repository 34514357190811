import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  AppBar,
  Toolbar,
  Link as MuiLink,
  Paper,
  useTheme,
  alpha,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  School as SchoolIcon,
  BarChart as BarChartIcon,
  AutoAwesome as AiIcon,
  AccountTree as KnowledgeIcon,
  TrendingUp as TrendingUpIcon,
  Sync as SyncIcon,
  Assignment as AssignmentIcon,
  Psychology as PsychologyIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

// Landing Header component with responsive menu
const LandingHeader = () => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? "rgba(255, 255, 255, 0.8)"
            : "rgba(18, 18, 18, 0.8)",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      data-testid="landing-header"
    >
      <Container maxWidth="lg">
        <Toolbar
          sx={{ display: "flex", justifyContent: "space-between", py: 1 }}
        >
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              fontFamily: "Poppins",
              fontWeight: 600,
              color: (theme) => theme.palette.primary.main,
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
            data-testid="header-logo-link"
          >
            <SchoolIcon sx={{ mr: 1 }} />
            Studyfy
          </Typography>

          {/* Desktop buttons (hidden on xs and sm) */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              component={RouterLink}
              to="/auth/login"
              variant="outlined"
              color="primary"
              sx={{ fontWeight: 500 }}
              data-testid="header-login-button"
            >
              Entrar
            </Button>
            <Button
              component={RouterLink}
              to="/auth/signup"
              variant="contained"
              color="primary"
              sx={{ fontWeight: 500 }}
              data-testid="header-signup-button"
            >
              Cadastrar
            </Button>
          </Box>

          {/* Mobile menu icon (visible only on xs and sm) */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              edge="end"
              color="primary"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={isMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem
                component={RouterLink}
                to="/auth/login"
                onClick={handleMenuClose}
                data-testid="menu-login-item"
              >
                Entrar
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to="/auth/signup"
                onClick={handleMenuClose}
                data-testid="menu-signup-item"
              >
                Cadastrar
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
const Section = ({ children, sx = {}, bg = "paper", ...props }) => {
  const theme = useTheme();
  const defaultSx = {
    py: { xs: 8, md: 12 },
    overflow: "hidden",
  };
  const backgroundSx = {
    bgcolor:
      bg === "alternate"
        ? theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[900]
        : "background.paper",
  };

  return (
    <Box sx={{ ...defaultSx, ...backgroundSx, ...sx }} {...props}>
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

const HeroSection = () => {
  const theme = useTheme();
  const heroIllustration = "/illustrations/studyfy-hero-main.svg";

  return (
    <Section
      sx={{
        pt: { xs: 15, md: 20 },
        pb: { xs: 8, md: 12 },
        textAlign: "center",
      }}
      data-testid="hero-section"
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "2.5rem", sm: "3.2rem", md: "4rem" },
            mb: 2,
            fontFamily: "Poppins, sans-serif",
            lineHeight: 1.2,
            color: "text.primary",
          }}
          data-testid="hero-title"
        >
          A forma mais inteligente de aprender e conquistar seus objetivos.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            fontWeight: 400,
            maxWidth: "650px",
            mx: "auto",
            color: "text.secondary",
            fontSize: { xs: "1.1rem", md: "1.3rem" },
            lineHeight: 1.6,
          }}
          data-testid="hero-subtitle"
        >
          Studyfy combina organização poderosa e IA personalizada para tornar
          seus estudos mais eficazes e agradáveis. Grátis para sempre.
        </Typography>
        <Button
          component={RouterLink}
          to="/auth/signup"
          variant="contained"
          color="primary"
          size="large"
          sx={{
            py: 1.5,
            px: 5,
            borderRadius: "16px",
            fontWeight: 600,
            fontSize: "1.1rem",
            textTransform: "uppercase",
            boxShadow: `0 4px 14px 0 ${alpha(
              theme.palette.primary.main,
              0.39
            )}`,
            letterSpacing: "0.5px",
            "&:hover": {
              transform: "translateY(-1px)",
              boxShadow: `0 6px 20px ${alpha(
                theme.palette.primary.main,
                0.45
              )}`,
            },
            transition: "all 0.2s ease-out",
          }}
          data-testid="hero-cta-button"
        >
          Comece Agora
        </Button>
      </Container>
      <Container maxWidth="lg" sx={{ mt: { xs: 6, md: 8 } }}>
        <Box
          component="img"
          // ** Needs Illustration **
          src={heroIllustration}
          alt="Ilustração de pessoa estudando feliz com a interface do Studyfy"
          sx={{
            width: "100%",
            maxWidth: "700px",
            height: "auto",
            display: "block",
            mx: "auto",
          }}
        />
      </Container>
    </Section>
  );
};

// 2. Benefits Section (Why Studyfy?) - *** VERTICAL CENTERING ADJUSTMENT ***
const BenefitsSection = () => {
  const theme = useTheme();
  const benefits = [
    {
      icon: AiIcon,
      title: "Aprendizado com IA",
      description: "Planos e conteúdos que se adaptam ao seu ritmo e estilo.",
    },
    {
      icon: KnowledgeIcon,
      title: "Organização Total",
      description: "Conecte ideias, notas e materiais em um só lugar.",
    },
    {
      icon: TrendingUpIcon,
      title: "Métodos Comprovados",
      description:
        "Use técnicas como flashcards e simulados para fixar o conhecimento.",
    },
    {
      icon: BarChartIcon,
      title: "Progresso Visível",
      description: "Acompanhe sua evolução com metas e análises claras.",
    },
  ];

  return (
    <Section bg="alternate" data-testid="benefits-section">
      <Typography
        variant="h3"
        component="h2"
        sx={{ fontWeight: 700, textAlign: "center", mb: 8 }}
      >
        Por que o Studyfy funciona?
      </Typography>
      {/* Ensure grid items stretch to equal height */}
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            {/* Flex container for vertical centering */}
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center content horizontally
                height: "100%", // Ensure box fills stretched grid item
                justifyContent: "center", // <<<--- Center content vertically
              }}
            >
              {/* Ícone */}
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: "primary.main",
                    width: 70,
                    height: 70,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <benefit.icon sx={{ fontSize: 35 }} />
                </Box>
              </Box>
              {/* Título */}
              <Typography
                variant="h6"
                component="h3"
                gutterBottom
                sx={{ fontWeight: 600, textAlign: "center", mb: 1 }}
              >
                {benefit.title}
              </Typography>
              {/* Descrição */}
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                {benefit.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

// 3. Feature Showcase Section - *** REDESIGNED AS SINGLE ROW (2x2 GRID) ***
const FeatureShowcaseSection = () => {
  const theme = useTheme();
  const showcases = [
    {
      id: "plan",
      icon: SyncIcon,
      title: "Planeje com inteligência",
      description:
        "Defina suas metas e deixe nossa IA criar um roteiro de estudos flexível e otimizado para você.",
      image: "/illustrations/feature-plan.svg",
    },
    {
      id: "learn",
      icon: KnowledgeIcon,
      title: "Construa conhecimento",
      description:
        "Organize notas, crie wikis visuais conectando ideias e tenha seu material sempre acessível.",
      image: "/illustrations/feature-learn.svg",
    },
    {
      id: "practice",
      icon: PsychologyIcon,
      title: "Pratique de forma eficaz",
      description:
        "Teste-se com simulados e flashcards gerados por IA, focados nas suas reais necessidades.",
      image: "/illustrations/feature-practice.svg",
    },
    {
      id: "analyze",
      icon: BarChartIcon,
      title: "Analise sua evolução",
      description:
        "Acompanhe seu progresso em tempo real com dashboards intuitivos e identifique pontos de melhoria.",
      image: "/illustrations/feature-analyze.svg",
    },
  ];

  return (
    <Section bg="paper" data-testid="feature-showcase-section">
      <Typography
        variant="h3"
        component="h2"
        sx={{ fontWeight: 700, textAlign: "center", mb: 8 }}
      >
        Recursos poderosos, integrados para você.
      </Typography>
      <Grid spacing={6} alignItems="center">
        {showcases.map((item) => (
          <Grid item key={item.id}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} sm={12} sx={{ textAlign: "center", width: "100%" }}>
                <Box
                  component="img"
                  src={item.image}
                  alt={`Ilustração para ${item.title}`}
                  sx={{
                    width: "100%",
                    maxWidth: { xs: "200px", sm: "250px" },
                    height: "auto",
                    display: "block",
                    mx: "auto",
                  }}
                />
              </Grid>
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={7}
                sx={{ textAlign: { xs: "center", sm: "center", width: "100%" } }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: 600, mb: 1.5 }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ lineHeight: 1.6 }}
                >
                  {item.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

// 4. Applications Section (Who is it for?) - Formatted like FeatureShowcaseSection
const ApplicationsSection = () => {
  const theme = useTheme();
  const applications = [
    {
      id: "students",
      title: "Estudantes Universitários",
      description:
        "Organize matérias, prepare-se para provas e colabore em trabalhos.",
      image: "/illustrations/app-students.svg",
    },
    {
      id: "candidates",
      title: "Concurseiros",
      description:
        "Siga o edital, faça simulados direcionados e maximize seu tempo de estudo.",
      image: "/illustrations/app-candidates.svg",
    },
    {
      id: "vestibulandos",
      title: "Vestibulandos",
      description:
        "Prepare-se para o ENEM e vestibulares com um plano adaptado às suas metas.",
      image: "/illustrations/app-vestibulandos.svg",
    },
    {
      id: "professionals",
      title: "Profissionais",
      description:
        "Estude para certificações, aprenda novas skills e impulsione sua carreira.",
      image: "/illustrations/app-professionals.svg",
    },
  ];

  return (
    <Section bg="alternate" data-testid="applications-section">
      <Typography
        variant="h1"
        component="h2"
        sx={{ fontWeight: 700, textAlign: "center", mb: 8 }}
      >
        Feito para todo tipo de estudante.
      </Typography>

      {/* Grid container for the 2x2 layout */}
      <Grid container spacing={6} alignItems="center">
        {applications.map((app) => (
          <Grid item xs={12} md={6} key={app.id} sx={{ width: "100%" }}>
            {/* Inner layout for text + image */}
            <Grid container spacing={4} alignItems="center">
              {/* Image Column */}
              <Grid
                item
                xs={12}
                sm={5}
                sx={{ textAlign: "center", width: "100%" }}
              >
                <Box
                  component="img"
                  src={app.image}
                  alt={app.title}
                  sx={{
                    width: "100%",
                    maxWidth: { xs: "400px", sm: "500px" },
                    height: "auto",
                    display: "block",
                    mx: "auto",
                  }}
                />
              </Grid>
              {/* Text Column */}
              <Grid
                item
                xs={12}
                sm={7}
                sx={{ textAlign: { xs: "center", width: "100%" } }}
              >
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ fontWeight: 600, mb: 1.5 }}
                >
                  {app.title}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ lineHeight: 1.6 }}
                >
                  {app.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

// 5. Final CTA Section - Refined Content
const FinalCTASection = () => {
  const theme = useTheme();
  return (
    <Section
      sx={{ py: { xs: 10, md: 15 }, textAlign: "center" }}
      data-testid="final-cta-section"
    >
      {" "}
      {/* Default background */}
      <Typography
        variant="h2" // Slightly smaller than hero H1
        component="h2"
        sx={{
          fontWeight: 700,
          mb: 3,
          maxWidth: "700px",
          mx: "auto",
          fontSize: { xs: "2.2rem", md: "3rem" },
          lineHeight: 1.2,
          textAlign: "center",
        }}
        data-testid="cta-title"
      >
        Comece a aprender do jeito inteligente.
      </Typography>
      <Typography
        variant="h6"
        color="text.secondary"
        sx={{
          mb: 5,
          maxWidth: "600px",
          mx: "auto",
          fontWeight: 400,
          lineHeight: 1.7,
          textAlign: "center",
        }}
        data-testid="cta-subtitle"
      >
        Junte-se a milhares de usuários que estão transformando seus estudos com
        Studyfy.
      </Typography>
      <Button
        component={RouterLink}
        to="/auth/signup"
        variant="contained"
        color="primary" // Match hero button color
        size="large"
        sx={{
          py: 1.5,
          px: 5,
          borderRadius: "16px", // Match hero button style
          fontWeight: 600,
          fontSize: "1.1rem",
          textTransform: "uppercase", // Match hero button style
          boxShadow: `0 4px 14px 0 ${alpha(theme.palette.primary.main, 0.39)}`, // Match hero button style
          letterSpacing: "0.5px",
          "&:hover": {
            transform: "translateY(-1px)",
            boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.45)}`, // Match hero button style
          },
          transition: "all 0.2s ease-out",
        }}
        data-testid="cta-button"
      >
        Comece Agora - É Grátis!
      </Button>
    </Section>
  );
};

// Footer component (Keep as is)
const LandingFooter = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: "auto",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
      data-testid="landing-footer"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm="auto">
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              <SchoolIcon sx={{ mr: 0.5, fontSize: "1rem" }} /> Studyfy &copy;{" "}
              {currentYear}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: { xs: "center", sm: "flex-end" },
              }}
            >
              <MuiLink
                component={RouterLink}
                to="/legal/terms-of-service"
                variant="body2"
                color="text.secondary"
                underline="hover"
                data-testid="footer-terms-link"
              >
                Termos de Uso
              </MuiLink>
              <MuiLink
                component={RouterLink}
                to="/legal/privacy-policy"
                variant="body2"
                color="text.secondary"
                underline="hover"
                data-testid="footer-privacy-link"
              >
                Política de Privacidade
              </MuiLink>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

// --- Main Landing Page Export ---
export default function LandingPage() {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        minHeight: "100vh",
        overflowX: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LandingHeader />

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          flexGrow: 1,
        }}
      >
        {/* New Structure */}
        <HeroSection />
        <BenefitsSection />
        <FeatureShowcaseSection />
        <ApplicationsSection />
        <FinalCTASection />
      </Box>

      <LandingFooter />
    </Box>
  );
}
