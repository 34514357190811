import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LoadingScreen from '../common/LoadingScreen';

/**
 * Component to handle authentication state and redirect users accordingly
 * 
 * This component will be mounted at app initialization and will redirect 
 * users based on their authentication status.
 */
const AuthRedirector = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (loading) return;
    
    const isPublicRoute = 
      location.pathname.startsWith("/auth") ||
      location.pathname.startsWith("/legal") ||
      location.pathname === "/";

    // If user is not logged in and trying to access a protected route,
    // redirect to login
    if (!currentUser && !isPublicRoute) {
      navigate('/auth/login', { replace: true });
    }
    
    // If user is logged in and trying to access auth routes,
    // redirect to dashboard
    if (currentUser && location.pathname.startsWith('/auth')) {
      navigate('/dashboard', { replace: true });
    }
    
  }, [currentUser, loading, navigate, location]);
  
  if (loading) {
    return <LoadingScreen message="Carregando sua sessão..." />;
  }
  
  // This component doesn't render anything
  return null;
};

export default AuthRedirector; 