import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AccessTime as AccessTimeIcon,
  ArrowForward as ArrowForwardIcon,
  Help as HelpIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
}));

const CircularProgressWithLabel = ({ value, color }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={56}
        thickness={4}
        color={color}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" fontWeight="bold">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const QuizCard = ({ quiz }) => {
  const {
    title,
    description,
    duration,
    questionsCount,
    completed,
    score,
    difficulty,
    category,
    correct = 0,
    incorrect = 0,
    isNew,
    isAI,
  } = quiz;

  // Format duration in hours and minutes
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return hours > 0 ? `${hours}h${mins > 0 ? ` ${mins}min` : ''}` : `${mins}min`;
  };

  // Get difficulty color
  const getDifficultyColor = (level) => {
    switch (level.toLowerCase()) {
      case 'fácil':
        return 'success';
      case 'médio':
        return 'warning';
      case 'difícil':
        return 'error';
      default:
        return 'primary';
    }
  };

  return (
    <StyledCard>
      <CardContent sx={{ flex: '1 0 auto', p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Chip
            label={category}
            color="primary"
            size="small"
            sx={{ fontWeight: 500 }}
          />
          
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isNew && (
              <Chip
                label="Novo"
                color="secondary"
                size="small"
                sx={{ fontWeight: 500 }}
              />
            )}
            
            {isAI && (
              <Chip
                label="IA"
                color="info"
                size="small"
                sx={{ fontWeight: 500 }}
              />
            )}
            
            <Chip
              label={difficulty}
              color={getDifficultyColor(difficulty)}
              size="small"
              sx={{ fontWeight: 500 }}
            />
          </Box>
        </Box>
        
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {title}
        </Typography>
        
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: '40px'
          }}
        >
          {description}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
            <Typography variant="caption" color="text.secondary">
              {formatDuration(duration)}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <HelpIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
            <Typography variant="caption" color="text.secondary">
              {questionsCount} questões
            </Typography>
          </Box>
        </Box>
        
        {completed && (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 1,
            p: 2,
            backgroundColor: (theme) => theme.palette.background.default,
            borderRadius: 2,
          }}>
            <CircularProgressWithLabel value={score} color={score >= 70 ? 'success' : score >= 50 ? 'warning' : 'error'} />
            
            <Box sx={{ ml: 2, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CheckIcon fontSize="small" color="success" sx={{ mr: 0.5 }} />
                <Typography variant="body2">
                  {correct} corretas
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CloseIcon fontSize="small" color="error" sx={{ mr: 0.5 }} />
                <Typography variant="body2">
                  {incorrect} incorretas
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </CardContent>
      
      <CardActions sx={{ p: 2, pt: 0, mt: 'auto' }}>
        <Button
          variant={completed ? "outlined" : "contained"}
          size="small"
          endIcon={<ArrowForwardIcon />}
          sx={{ ml: 'auto' }}
        >
          {completed ? 'Ver resultados' : 'Iniciar simulado'}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default QuizCard; 