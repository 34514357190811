import { useEffect, useRef } from 'react';

/**
 * Hook to track if component is mounted.
 * Useful to prevent memory leaks from setting state after unmounting.
 * @returns {React.MutableRefObject<boolean>} A ref object with current value as mounting state
 */
const useMounted = () => {
  const mounted = useRef(false);
  
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  
  return mounted;
};

export default useMounted; 