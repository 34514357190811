import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Typography,
  Button,
  Chip,
  LinearProgress,
  Rating,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AccessTime as AccessTimeIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
}));

const CourseCard = ({ course }) => {
  const { 
    title, 
    description, 
    image, 
    progress, 
    duration, 
    category, 
    rating, 
    isNew, 
    instructor,
    isPremium 
  } = course;
  
  // Format duration in hours
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h${mins > 0 ? ` ${mins}min` : ''}`;
  };
  
  return (
    <StyledCard>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={title}
          sx={{ objectFit: 'cover' }}
        />
        
        {/* Category chip */}
        <Chip
          label={category}
          color="primary"
          size="small"
          sx={{
            position: 'absolute',
            top: 12,
            left: 12,
            fontWeight: 500,
          }}
        />
        
        {/* New or Premium badges */}
        {(isNew || isPremium) && (
          <Box sx={{ position: 'absolute', top: 12, right: 12, display: 'flex', gap: 1 }}>
            {isNew && (
              <Chip
                label="Novo"
                color="secondary"
                size="small"
                sx={{ fontWeight: 500 }}
              />
            )}
            
            {isPremium && (
              <Chip
                label="Premium"
                color="warning"
                size="small"
                sx={{ fontWeight: 500 }}
              />
            )}
          </Box>
        )}
      </Box>
      
      <CardContent sx={{ flex: '1 0 auto', p: 3 }}>
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {title}
        </Typography>
        
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: '40px'
          }}
        >
          {description}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Rating value={rating} precision={0.5} size="small" readOnly />
          <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
            {rating.toFixed(1)}
          </Typography>
        </Box>
        
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Instrutor: {instructor}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
          <Typography variant="caption" color="text.secondary">
            {formatDuration(duration)}
          </Typography>
        </Box>
        
        {progress > 0 && (
          <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
              <Typography variant="body2">Progresso</Typography>
              <Typography variant="body2" color="text.secondary">{progress}%</Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 6, borderRadius: 3 }}
            />
          </Box>
        )}
      </CardContent>
      
      <CardActions sx={{ p: 2, pt: 0, mt: 'auto' }}>
        <Button
          size="small"
          endIcon={<ArrowForwardIcon />}
          sx={{ ml: 'auto' }}
        >
          {progress > 0 ? 'Continuar' : 'Iniciar curso'}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default CourseCard; 