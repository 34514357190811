import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { School as SchoolIcon } from '@mui/icons-material';

const Logo = ({ variant = 'primary', size = 'medium', linkToHome = true }) => {
  // Define size styles
  const sizeStyles = {
    small: {
      iconSize: 24,
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    medium: {
      iconSize: 32,
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    large: {
      iconSize: 40,
      fontSize: '2rem',
      fontWeight: 700,
    },
  };

  // Define variant styles - primary is colored, secondary is light/white
  const variantStyles = {
    primary: {
      textColor: 'primary.main',
      iconColor: 'primary.main',
    },
    secondary: {
      textColor: 'common.white',
      iconColor: 'common.white',
    },
  };

  const { iconSize, fontSize, fontWeight } = sizeStyles[size];
  const { textColor, iconColor } = variantStyles[variant];

  const logoContent = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <SchoolIcon sx={{ fontSize: iconSize, color: iconColor }} />
      <Typography
        variant="h6"
        sx={{
          fontWeight,
          fontSize,
          color: textColor,
          fontFamily: 'Poppins, sans-serif',
          letterSpacing: '-0.02em',
        }}
      >
        Studyfy
      </Typography>
    </Box>
  );

  if (linkToHome) {
    return (
      <Link to="/" style={{ textDecoration: 'none' }}>
        {logoContent}
      </Link>
    );
  }

  return logoContent;
};

export default Logo; 