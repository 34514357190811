import React, { lazy } from "react";
import { Route, Navigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Dashboard from "../pages/dashboard/Dashboard";
import LandingPage from "../pages/landing/LandingPage";
import { Login, Signup, ForgotPassword, Actions } from "../pages/auth";
import { useAuth } from "../contexts/AuthContext";

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

// Public Route (not accessible when logged in)
const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

// Lazy load pages
const LoginPage = lazy(() => import("../pages/auth/Login"));
const SignupPage = lazy(() => import("../pages/auth/Signup"));
const ForgotPasswordPage = lazy(() => import("../pages/auth/ForgotPassword"));
const VerifyEmailPage = lazy(() => import("../pages/auth/VerifyEmailAddress")); // Check filename
const ActionsPage = lazy(() => import("../pages/auth/Actions")); // Check filename

const DashboardPage = lazy(() => import("../pages/dashboard/Dashboard")); // Example dashboard page

// --- NEW LEGAL PAGES ---
const TermsOfServicePage = lazy(() => import("../pages/legal/TermsOfService"));
const PrivacyPolicyPage = lazy(() => import("../pages/legal/PrivacyPolicy"));
// ----------------------

const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/auth/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/signup",
    element: (
      <PublicRoute>
        <Signup />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/forgot-password",
    element: (
      <PublicRoute>
        <ForgotPassword />
      </PublicRoute>
    ),
  },
  {
    path: "/auth/action",
    element: <Actions />,
  },
  {
    path: "/auth",
    element: <Navigate to="/auth/login" replace />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/legal/terms-of-service",
    element: <TermsOfServicePage />,
  },
  {
    element: <PrivacyPolicyPage />,
    path: "/legal/privacy-policy",
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export const renderRoutes = () =>
  routes.map((route) => (
    <Route key={route.path} path={route.path} element={route.element} />
  ));

export default routes;
