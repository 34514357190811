import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { applyActionCode } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { Card, CardContent, Typography, Box, Container, Alert, Button, CircularProgress } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const VerifyEmailAddress = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  const location = useLocation();
  
  // Get actionCode and continueUrl from URL params
  const queryParams = new URLSearchParams(location.search);
  const actionCode = queryParams.get('oobCode');
  const continueUrl = queryParams.get('continueUrl');
  const mode = queryParams.get('mode');
  const lang = queryParams.get('lang') || 'en';
  
  useEffect(() => {
    if (!actionCode || mode !== 'verifyEmail') {
      setError('Invalid email verification link.');
      setLoading(false);
      return;
    }
    
    handleVerifyEmail(actionCode);
  }, [actionCode, mode]);
  
  const handleVerifyEmail = async (actionCode) => {
    try {
      // Apply the email verification code
      await applyActionCode(auth, actionCode);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setError('Invalid or expired verification link. Please request a new verification email.');
      setLoading(false);
      console.error('Error verifying email:', error);
    }
  };
  
  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" p={3}>
              <CircularProgress />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Verifying your email address...
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom>
                Email Verification Failed
              </Typography>
            </Box>
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
            <Box display="flex" justifyContent="center">
              <Button 
                component={Link} 
                to="/auth/login" 
                variant="contained" 
                color="primary"
              >
                Go to Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <VerifiedUserIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" component="div" gutterBottom>
              Email Verified Successfully
            </Typography>
          </Box>
          
          <Alert severity="success" sx={{ mb: 3 }}>
            Your email address has been successfully verified. You can now access all features of your account.
          </Alert>
          
          <Box display="flex" justifyContent="center" gap={2}>
            {continueUrl ? (
              <Button 
                href={continueUrl}
                variant="contained" 
                color="primary"
              >
                Continue to App
              </Button>
            ) : (
              <Button 
                component={Link} 
                to="/auth/login" 
                variant="contained" 
                color="primary"
              >
                Go to Sign In
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default VerifyEmailAddress; 