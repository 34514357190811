import React from 'react';
import { Box, Card, CardContent, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
}));

const IconWrapper = styled(Avatar)(({ theme, color }) => ({
  width: 48,
  height: 48,
  backgroundColor: theme.palette[color || 'primary'].main,
}));

const StatCard = ({ title, value, subtitle, icon, color = 'primary' }) => {
  return (
    <StyledCard>
      <CardContent sx={{ 
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}>
        <Box>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
            {value}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <IconWrapper color={color}>
          {icon}
        </IconWrapper>
      </CardContent>
    </StyledCard>
  );
};

export default StatCard; 