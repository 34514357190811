import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
  Avatar,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ArrowForward as ArrowForwardIcon,
  Lightbulb as LightbulbIcon,
  School as SchoolIcon,
  Assignment as AssignmentIcon,
  Quiz as QuizIcon,
  MenuBook as MenuBookIcon,
  FlashOn as FlashOnIcon,
} from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 1.5,
  transition: 'transform 0.3s, box-shadow 0.3s',
  borderLeft: `4px solid ${theme.palette.info.main}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[3],
  },
}));

// Map recommendation types to icons
const typeIcons = {
  course: <SchoolIcon />,
  quiz: <QuizIcon />,
  material: <MenuBookIcon />,
  flashcard: <FlashOnIcon />,
  assignment: <AssignmentIcon />,
};

const RecommendationCard = ({ recommendation }) => {
  const {
    title,
    description,
    type,
    actionLabel,
    actionUrl,
    confidence,
    reasonsTitle,
    reasons = [],
  } = recommendation;

  // Get the icon based on type
  const getIcon = (type) => {
    return typeIcons[type] || <LightbulbIcon />;
  };

  // Get type label
  const getTypeLabel = (type) => {
    const labels = {
      course: 'Curso',
      quiz: 'Simulado',
      material: 'Material',
      flashcard: 'Flashcards',
      assignment: 'Atividade',
    };
    return labels[type] || 'Recomendação';
  };

  return (
    <StyledCard>
      <CardContent sx={{ flex: '1 0 auto', p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                bgcolor: 'info.main',
                width: 24,
                height: 24,
                mr: 1,
              }}
            >
              <LightbulbIcon fontSize="small" />
            </Avatar>
            <Typography variant="subtitle2" color="info.main">
              Recomendação IA
            </Typography>
          </Box>
          
          <Chip
            label={getTypeLabel(type)}
            color="default"
            size="small"
            icon={getIcon(type)}
            sx={{ fontWeight: 500 }}
          />
        </Box>
        
        <Typography
          variant="h6"
          component="div"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {title}
        </Typography>
        
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            height: '40px'
          }}
        >
          {description}
        </Typography>
        
        <Box>
          <Typography
            variant="body2"
            fontWeight="medium"
            sx={{ mb: 1 }}
          >
            {reasonsTitle || 'Por que estamos recomendando:'}
          </Typography>
          
          <Box
            component="ul"
            sx={{
              pl: 2,
              m: 0,
            }}
          >
            {reasons.map((reason, index) => (
              <Typography
                component="li"
                variant="body2"
                color="text.secondary"
                key={index}
                sx={{ mb: 0.5 }}
              >
                {reason}
              </Typography>
            ))}
          </Box>
        </Box>
      </CardContent>
      
      <CardActions sx={{ p: 2, pt: 0, mt: 'auto' }}>
        <Tooltip title={`${confidence}% de confiança na recomendação`} placement="top">
          <Chip
            label={`${confidence}% de confiança`}
            size="small"
            variant="outlined"
          />
        </Tooltip>
        
        <Button
          size="small"
          variant="contained"
          color="info"
          endIcon={<ArrowForwardIcon />}
          sx={{ ml: 'auto' }}
        >
          {actionLabel || 'Ver recomendação'}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default RecommendationCard;