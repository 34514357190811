import React, { createContext, useContext, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider, useTheme as useMuiTheme } from '@mui/material/styles';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { selectThemeMode, toggleThemeMode, setThemeMode } from '../store/slices/uiSlice';
import createTheme from '../config/theme';

// Create context
const ThemeContext = createContext({
  themeMode: 'light',
  toggleTheme: () => {},
  setTheme: () => {},
});

// Custom hook to use theme context
export const useThemeContext = () => useContext(ThemeContext);

// Theme provider component
export const ThemeProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector(selectThemeMode);
  
  // Create theme based on current mode
  const theme = useMemo(() => createTheme(themeMode), [themeMode]);
  
  // Toggle theme mode
  const toggleTheme = () => {
    dispatch(toggleThemeMode());
  };
  
  // Set theme mode
  const setTheme = (mode) => {
    dispatch(setThemeMode(mode));
  };
  
  // Value to be provided to context consumers
  const contextValue = useMemo(() => ({
    themeMode,
    toggleTheme,
    setTheme,
  }), [themeMode]);
  
  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

// For direct access to MUI theme object
export const useTheme = () => useMuiTheme();

export default ThemeProvider; 