import React from 'react';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Badge,
  Typography,
  SwipeableDrawer,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  School as SchoolIcon,
  MenuBook as MenuBookIcon,
  Assignment as AssignmentIcon,
  Quiz as QuizIcon,
  Schedule as ScheduleIcon,
  TrendingUp as TrendingUpIcon,
  Settings as SettingsIcon,
  ExpandLess,
  ExpandMore,
  StarBorder,
  Bookmark as BookmarkIcon,
  FlashOn as FlashOnIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { selectSidebarOpen, setSidebarOpen } from '../../store/slices/uiSlice';

// Sidebar width
const DRAWER_WIDTH = 260;

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const [openCourses, setOpenCourses] = React.useState(false);
  
  const handleCoursesClick = () => {
    setOpenCourses(!openCourses);
  };

  const handleDrawerToggle = (open) => () => {
    dispatch(setSidebarOpen(open));
  };

  // Main navigation items
  const mainNavItems = [
    { 
      text: 'Dashboard', 
      icon: <DashboardIcon />, 
      path: '/dashboard' 
    },
    { 
      text: 'Meu plano de estudos', 
      icon: <ScheduleIcon />, 
      path: '/study-plan' 
    },
    { 
      text: 'Cursos', 
      icon: <SchoolIcon />, 
      path: '/courses',
      hasChildren: true,
      handleClick: handleCoursesClick,
      isOpen: openCourses,
      children: [
        { text: 'Todos os cursos', path: '/courses/all' },
        { text: 'Em andamento', path: '/courses/in-progress' },
        { text: 'Recomendados', path: '/courses/recommended' },
      ]
    },
    { 
      text: 'Simulados', 
      icon: <QuizIcon />, 
      path: '/quizzes',
      badge: 2,
    },
    { 
      text: 'Flashcards', 
      icon: <FlashOnIcon />, 
      path: '/flashcards' 
    },
    { 
      text: 'Materiais', 
      icon: <MenuBookIcon />, 
      path: '/materials' 
    },
    { 
      text: 'Anotações', 
      icon: <AssignmentIcon />, 
      path: '/notes' 
    },
  ];

  // Secondary navigation items
  const secondaryNavItems = [
    { 
      text: 'Estatísticas', 
      icon: <TrendingUpIcon />, 
      path: '/statistics' 
    },
    { 
      text: 'Favoritos', 
      icon: <BookmarkIcon />, 
      path: '/bookmarks' 
    },
    { 
      text: 'Comunidade', 
      icon: <PeopleIcon />, 
      path: '/community' 
    },
    { 
      text: 'Configurações', 
      icon: <SettingsIcon />, 
      path: '/settings' 
    },
  ];

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>      
      <Box sx={{ overflow: 'auto', flex: '1 1 auto' }}>
        <List sx={{ width: '100%' }} component="nav">
          {mainNavItems.map((item) => (
            <React.Fragment key={item.text}>
              <ListItem disablePadding>
                <ListItemButton 
                  component="a" 
                  href={item.path}
                  onClick={item.handleClick}
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: (theme) => theme.palette.mode === 'light' 
                        ? 'rgba(0, 0, 0, 0.04)' 
                        : 'rgba(255, 255, 255, 0.08)' 
                    },
                    borderRadius: '0 20px 20px 0',
                    mx: 1,
                    py: 1,
                  }}
                >
                  <ListItemIcon>
                    {item.badge ? (
                      <Badge badgeContent={item.badge} color="error">
                        {item.icon}
                      </Badge>
                    ) : (
                      item.icon
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.hasChildren && (item.isOpen ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
              </ListItem>
              
              {item.hasChildren && (
                <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((child) => (
                      <ListItemButton 
                        key={child.text} 
                        component="a" 
                        href={child.path} 
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary={child.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        
        <Divider sx={{ my: 1 }} />
        
        <List sx={{ width: '100%' }}>
          {secondaryNavItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton 
                component="a" 
                href={item.path}
                sx={{ 
                  '&:hover': { 
                    backgroundColor: (theme) => theme.palette.mode === 'light' 
                      ? 'rgba(0, 0, 0, 0.04)' 
                      : 'rgba(255, 255, 255, 0.08)' 
                  },
                  borderRadius: '0 20px 20px 0',
                  mx: 1,
                  py: 1,
                }}
              >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box component="nav" aria-label="navigation sidebar">
      {/* Mobile drawer (SwipeableDrawer for better mobile UX) */}
      <SwipeableDrawer
        variant="temporary"
        open={sidebarOpen}
        onOpen={handleDrawerToggle(true)}
        onClose={handleDrawerToggle(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
            zIndex: (theme) => theme.zIndex.appBar + 100,
          },
        }}
      >
        {drawer}
      </SwipeableDrawer>
      
      {/* Desktop drawer */}
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: sidebarOpen ? DRAWER_WIDTH : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            position: 'fixed',
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            top: '64px',
            height: 'calc(100% - 64px)',
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            zIndex: (theme) => theme.zIndex.appBar - 1,
            visibility: sidebarOpen ? 'visible' : 'hidden',
            transform: sidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
            transition: (theme) => theme.transitions.create('transform', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar; 