import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { useTheme } from '../../contexts/ThemeContext';
import Header from './Header';
import Sidebar from './Sidebar';
import { useAppSelector } from '../../store/hooks';
import { selectSidebarOpen } from '../../store/slices/uiSlice';
import useMounted from '../../hooks/useMounted';

// Sidebar width - should match the value in Sidebar.jsx
const DRAWER_WIDTH = 260;
// Header height
const HEADER_HEIGHT = 64;

const Layout = ({ children }) => {
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const theme = useTheme();
  const mounted = useMounted();

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden', height: '100vh' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      
      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100%',
          width: '100%', 
          overflow: 'auto',
          mt: `${HEADER_HEIGHT}px`, // Account for fixed header height
          backgroundColor: theme.palette.background.default,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(sidebarOpen && {
            [theme.breakpoints.up('sm')]: {
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
            },
          }),
        }}
      >
        <Box sx={{ p: { xs: 2, sm: 3 }, maxWidth: '100%' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout; 