import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { app } from './config/firebase'; // Import Firebase app initialization

// Add error event listeners for debugging
if (process.env.NODE_ENV === 'development') {
  window.addEventListener('error', (event) => {
    console.error('Global error:', event.error);
  });
  
  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled Promise rejection:', event.reason);
  });
}

// Load app with specific configuration for better error handling
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Strict mode can sometimes cause double-renders and issues with certain components
  // Temporarily disable strict mode if facing issues with double rendering
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
