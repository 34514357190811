import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { checkActionCode, applyActionCode, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { Card, CardContent, Typography, Box, Container, Alert, Button, CircularProgress } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const RevokeEmail = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [restoredEmail, setRestoredEmail] = useState('');
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  
  const location = useLocation();
  
  // Get actionCode from URL
  const queryParams = new URLSearchParams(location.search);
  const actionCode = queryParams.get('oobCode');
  const mode = queryParams.get('mode');
  const lang = queryParams.get('lang') || 'en';
  
  useEffect(() => {
    if (!actionCode || mode !== 'recoverEmail') {
      setError('Invalid email recovery link.');
      setLoading(false);
      return;
    }
    
    handleRecoverEmail(actionCode);
  }, [actionCode, mode]);
  
  const handleRecoverEmail = async (actionCode) => {
    try {
      // Confirm the action code is valid and get info
      const info = await checkActionCode(auth, actionCode);
      
      // Get the restored email address
      const email = info.data.email;
      setRestoredEmail(email);
      
      // Apply the action code to revert the email change
      await applyActionCode(auth, actionCode);
      
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setError('Invalid or expired action code. The email recovery link may have expired.');
      setLoading(false);
      console.error('Error recovering email:', error);
    }
  };
  
  const handleResetPassword = async () => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, restoredEmail);
      setResetPasswordSent(true);
      setLoading(false);
    } catch (error) {
      setError('Error sending password reset email: ' + error.message);
      setLoading(false);
      console.error('Error sending password reset:', error);
    }
  };
  
  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" p={3}>
              <CircularProgress />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Processing your request...
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom>
                Email Recovery Failed
              </Typography>
            </Box>
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
            <Box display="flex" justifyContent="center">
              <Button 
                component={Link} 
                to="/auth/login" 
                variant="contained" 
                color="primary"
              >
                Go to Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <MarkEmailReadIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" component="div" gutterBottom>
              Email Restored Successfully
            </Typography>
          </Box>
          
          <Alert severity="success" sx={{ mb: 3 }}>
            Your email address has been successfully restored to <strong>{restoredEmail}</strong>.
          </Alert>
          
          {!resetPasswordSent ? (
            <Box>
              <Typography variant="body1" paragraph>
                If you did not request this change, your account may have been compromised.
              </Typography>
              <Box display="flex" justifyContent="center" mb={2}>
                <Button 
                  onClick={handleResetPassword}
                  variant="contained" 
                  color="warning"
                  disabled={loading}
                >
                  {loading ? 'Sending...' : 'Reset Your Password'}
                </Button>
              </Box>
            </Box>
          ) : (
            <Alert severity="info" sx={{ mb: 3 }}>
              A password reset email has been sent to your email address. Please check your inbox.
            </Alert>
          )}
          
          <Box display="flex" justifyContent="center">
            <Button 
              component={Link} 
              to="/auth/login" 
              variant="outlined" 
              color="primary"
            >
              Go to Sign In
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default RevokeEmail; 