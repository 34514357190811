import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { Card, CardContent, Typography, TextField, Button, Box, Container, Alert } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validCode, setValidCode] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get actionCode from URL
  const queryParams = new URLSearchParams(location.search);
  const actionCode = queryParams.get('oobCode');
  const mode = queryParams.get('mode');
  
  useEffect(() => {
    if (!actionCode) {
      setError('Invalid password reset link.');
      return;
    }
    
    // Verify the action code is valid
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setEmail(email);
        setValidCode(true);
      })
      .catch((error) => {
        setError('Invalid or expired password reset link. Please request a new one.');
        console.error('Error verifying reset code:', error);
      });
  }, [actionCode]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    
    if (password.length < 6) {
      setError('Password should be at least 6 characters long.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      await confirmPasswordReset(auth, actionCode, password);
      setSuccess(true);
      setLoading(false);
      // Reset form
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError('Error resetting password: ' + error.message);
      setLoading(false);
      console.error('Error resetting password:', error);
    }
  };
  
  if (!actionCode || mode !== 'resetPassword') {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" align="center" gutterBottom>
              Invalid Reset Link
            </Typography>
            <Alert severity="error">
              The password reset link is invalid or has expired.
            </Alert>
            <Box mt={3} display="flex" justifyContent="center">
              <Button 
                component={Link} 
                to="/auth/forgot-password" 
                variant="contained" 
                color="primary"
              >
                Request New Reset Link
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  if (success) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <LockResetIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
              <Typography variant="h5" component="div" gutterBottom>
                Password Reset Successfully
              </Typography>
            </Box>
            <Alert severity="success">
              Your password has been successfully reset.
            </Alert>
            <Box mt={3} display="flex" justifyContent="center">
              <Button 
                component={Link} 
                to="/auth/login" 
                variant="contained" 
                color="primary"
              >
                Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    );
  }
  
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <LockResetIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" component="div" gutterBottom>
              Reset Your Password
            </Typography>
            {validCode && (
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Create a new password for {email}
              </Typography>
            )}
          </Box>
          
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          
          {validCode && (
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </Button>
            </Box>
          )}
          
          <Box mt={2} display="flex" justifyContent="center">
            <Button 
              component={Link} 
              to="/auth/login" 
              variant="text" 
              color="primary"
            >
              Back to Sign In
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ResetPassword; 