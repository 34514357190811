import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const SectionTitle = ({ 
  title, 
  subtitle, 
  actionLabel, 
  actionUrl,
  icon,
  divider = true,
  spacing = 2
}) => {
  return (
    <Box sx={{ width: '100%', mb: spacing }}>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon && (
            <Box sx={{ mr: 1, display: 'flex', color: 'primary.main' }}>
              {icon}
            </Box>
          )}
          
          <Typography 
            variant="h5" 
            component="h2"
            sx={{ 
              fontWeight: 600,
              color: 'text.primary',
            }}
          >
            {title}
          </Typography>
        </Box>
        
        {actionLabel && (
          <Button 
            variant="text" 
            size="small" 
            endIcon={<ArrowForwardIcon />}
            href={actionUrl}
            component={actionUrl ? 'a' : 'button'}
            onClick={actionUrl ? undefined : (e) => e.preventDefault()}
          >
            {actionLabel}
          </Button>
        )}
      </Box>
      
      {subtitle && (
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ mb: 1 }}
        >
          {subtitle}
        </Typography>
      )}
      
      {divider && <Divider sx={{ mt: 1 }} />}
    </Box>
  );
};

export default SectionTitle; 