import React from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  Avatar, 
  Button, 
  Divider,
  Card,
  CardContent,
  useMediaQuery,
  Container
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Timeline as TimelineIcon,
  School as SchoolIcon,
  Quiz as QuizIcon,
  TrendingUp as TrendingUpIcon,
  Lightbulb as LightbulbIcon,
  AccessTime as AccessTimeIcon,
  CalendarMonth as CalendarMonthIcon,
  FlashOn as FlashOnIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

// Import components
import StatCard from '../../components/common/StatCard';
import SectionTitle from '../../components/common/SectionTitle';
import StudyPlanCard from '../../components/study/StudyPlanCard';
import CourseCard from '../../components/study/CourseCard';
import QuizCard from '../../components/quiz/QuizCard';
import RecommendationCard from '../../components/ai/RecommendationCard';

// Mock data
const user = {
  name: 'Ana Silva',
  avatar: 'https://i.pravatar.cc/150?img=5',
  streak: 7,
  totalHours: 42,
  level: 5,
};

const stats = [
  { 
    title: 'Tempo de estudo', 
    value: '8h 30min', 
    subtitle: 'Esta semana', 
    icon: <AccessTimeIcon />, 
    color: 'primary' 
  },
  { 
    title: 'Sequência', 
    value: `${user.streak} dias`, 
    subtitle: 'Seu recorde: 12 dias', 
    icon: <TimelineIcon />, 
    color: 'secondary' 
  },
  { 
    title: 'Simulados', 
    value: '12', 
    subtitle: '85% de acertos', 
    icon: <QuizIcon />, 
    color: 'success' 
  },
  { 
    title: 'Flashcards', 
    value: '230', 
    subtitle: '68 revisados hoje', 
    icon: <FlashOnIcon />, 
    color: 'warning' 
  },
];

const studyPlans = [
  {
    id: '1',
    title: 'Plano de Estudos para Concurso Público',
    description: 'Preparação completa para concurso com foco em Direito Administrativo e Constitucional',
    progress: 45,
    startDate: '2023-01-15',
    endDate: '2023-06-15',
    topics: ['Direito Administrativo', 'Direito Constitucional', 'Português', 'Raciocínio Lógico']
  },
  {
    id: '2',
    title: 'Certificação em Cloud Computing',
    description: 'Preparação para certificação AWS Solutions Architect Associate',
    progress: 72,
    startDate: '2023-02-20',
    endDate: '2023-05-20',
    topics: ['AWS', 'Cloud Computing', 'Networking', 'Security']
  },
  {
    id: '3',
    title: 'Aprendendo React e Firebase',
    description: 'Estudo autodidata de desenvolvimento web com React e Firebase',
    progress: 25,
    startDate: '2023-03-10',
    endDate: '2023-08-10',
    topics: ['React', 'Firebase', 'JavaScript', 'Web Development']
  }
];

const courses = [
  {
    id: '1',
    title: 'Fundamentos de React',
    description: 'Aprenda os conceitos fundamentais do React e crie aplicações modernas',
    image: 'https://via.placeholder.com/300x200/6A5AE0/FFFFFF/?text=React',
    progress: 65,
    duration: 480, // minutes
    category: 'Desenvolvimento Web',
    rating: 4.8,
    isNew: false,
    isPremium: true,
    instructor: 'João Ribeiro'
  },
  {
    id: '2',
    title: 'Direito Constitucional',
    description: 'Curso completo de Direito Constitucional com foco em concursos públicos',
    image: 'https://via.placeholder.com/300x200/513EEB/FFFFFF/?text=Direito',
    progress: 32,
    duration: 720,
    category: 'Direito',
    rating: 4.5,
    isNew: true,
    isPremium: false,
    instructor: 'Dra. Maria Pereira'
  },
  {
    id: '3',
    title: 'AWS Solutions Architect',
    description: 'Preparação completa para a certificação AWS Solutions Architect Associate',
    image: 'https://via.placeholder.com/300x200/6A5AE0/FFFFFF/?text=AWS',
    progress: 0,
    duration: 600,
    category: 'Cloud Computing',
    rating: 4.9,
    isNew: true,
    isPremium: true,
    instructor: 'Carlos Santos'
  },
];

const quizzes = [
  {
    id: '1',
    title: 'Simulado Direito Constitucional',
    description: 'Teste seus conhecimentos nos princípios fundamentais e direitos e garantias',
    duration: 120,
    questionsCount: 30,
    completed: true,
    score: 78,
    difficulty: 'Médio',
    category: 'Direito',
    correct: 23,
    incorrect: 7,
    isNew: false,
    isAI: true,
  },
  {
    id: '2',
    title: 'Quiz React Hooks',
    description: 'Teste prático sobre os principais Hooks do React e seu uso em aplicações',
    duration: 30,
    questionsCount: 15,
    completed: false,
    difficulty: 'Difícil',
    category: 'Desenvolvimento',
    isNew: true,
    isAI: false,
  },
];

const recommendations = [
  {
    id: '1',
    title: 'Revisão de Português para Concursos',
    description: 'Recomendamos este curso baseado no seu desempenho em simulados recentes',
    type: 'course',
    actionLabel: 'Ver curso',
    actionUrl: '/courses/123',
    confidence: 92,
    reasonsTitle: 'Razões para esta recomendação:',
    reasons: [
      'Você teve desempenho abaixo da média em questões de sintaxe',
      'Este tópico é comum em seus concursos-alvo',
      'Você não revisou este assunto nos últimos 30 dias'
    ]
  },
  {
    id: '2',
    title: 'Simulado personalizado de Direito Administrativo',
    description: 'Um simulado gerado por IA específico para suas áreas de melhoria',
    type: 'quiz',
    actionLabel: 'Iniciar simulado',
    actionUrl: '/quizzes/456',
    confidence: 85,
    reasonsTitle: 'Por que criamos este simulado:',
    reasons: [
      'Foco nos tópicos com menor desempenho',
      'Questões similares às dos seus concursos-alvo',
      'Adaptado ao seu nível atual de conhecimento'
    ]
  },
];

const todaysSchedule = [
  {
    id: '1',
    title: 'Direito Administrativo',
    startTime: '08:30',
    endTime: '10:00',
    completed: true,
  },
  {
    id: '2',
    title: 'Revisão de Flashcards',
    startTime: '10:15',
    endTime: '11:00',
    completed: true,
  },
  {
    id: '3',
    title: 'Simulado de Português',
    startTime: '14:00',
    endTime: '16:00',
    completed: false,
  },
  {
    id: '4',
    title: 'Leitura de Artigos',
    startTime: '17:30',
    endTime: '18:30',
    completed: false,
  },
];

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Box>
      <Container maxWidth="xl" disableGutters sx={{ pb: 4 }}>
        {/* Greeting and overview */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper 
              sx={{ 
                p: 3, 
                display: 'flex', 
                flexDirection: 'column',
                borderRadius: 3,
                mb: 3,
                backgroundImage: (theme) => 
                  `linear-gradient(120deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                color: 'white'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  src={user.avatar}
                  alt={user.name}
                  sx={{ width: 56, height: 56, mr: 2, border: '2px solid white' }}
                />
                <Box>
                  <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold' }}>
                    Olá, {user.name}!
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.9 }}>
                    Bem-vindo de volta ao seu painel de estudos
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TimelineIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Sequência de {user.streak} dias
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    {user.totalHours} horas de estudo no total
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SchoolIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Nível {user.level}
                  </Typography>
                </Box>
              </Box>
            </Paper>
            
            {/* Stats */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {stats.map((stat, index) => (
                <Grid item xs={6} md={3} key={index}>
                  <StatCard
                    title={stat.title}
                    value={stat.value}
                    subtitle={stat.subtitle}
                    icon={stat.icon}
                    color={stat.color}
                  />
                </Grid>
              ))}
            </Grid>
            
            {/* Study Plans */}
            <SectionTitle
              title="Meus Planos de Estudo"
              subtitle="Acompanhe seu progresso nos planos de estudo ativos"
              actionLabel="Ver todos"
              actionUrl="/study-plans"
              icon={<CalendarMonthIcon />}
            />
            
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {studyPlans.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <StudyPlanCard plan={plan} />
                </Grid>
              ))}
            </Grid>
            
            {/* Courses */}
            <SectionTitle
              title="Meus Cursos"
              subtitle="Continue de onde parou ou inicie um novo curso"
              actionLabel="Ver biblioteca"
              actionUrl="/courses"
              icon={<SchoolIcon />}
            />
            
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {courses.map((course) => (
                <Grid item xs={12} sm={6} md={4} key={course.id}>
                  <CourseCard course={course} />
                </Grid>
              ))}
            </Grid>
            
            {/* Quizzes */}
            <SectionTitle
              title="Simulados"
              subtitle="Teste seus conhecimentos com simulados personalizados"
              actionLabel="Ver todos"
              actionUrl="/quizzes"
              icon={<QuizIcon />}
            />
            
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {quizzes.map((quiz) => (
                <Grid item xs={12} sm={6} key={quiz.id}>
                  <QuizCard quiz={quiz} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          
          {/* Right sidebar */}
          <Grid item xs={12} md={4}>
            {/* Today's Schedule */}
            <Paper sx={{ p: 3, mb: 3, borderRadius: 3 }}>
              <SectionTitle
                title="Agenda de Hoje"
                icon={<AccessTimeIcon />}
                divider={false}
                spacing={1}
              />
              
              {todaysSchedule.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Box sx={{ py: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                      <Box 
                        sx={{ 
                          width: 12, 
                          height: 12, 
                          borderRadius: '50%', 
                          bgcolor: item.completed ? 'success.main' : 'primary.main',
                          mr: 2 
                        }} 
                      />
                      <Box>
                        <Typography variant="body2" fontWeight={500}>
                          {item.title}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {item.startTime} - {item.endTime}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        color: item.completed ? 'success.main' : 'text.secondary',
                        fontWeight: item.completed ? 500 : 400,
                      }}
                    >
                      {item.completed ? 'Concluído' : 'Pendente'}
                    </Typography>
                  </Box>
                  {index < todaysSchedule.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }}
              >
                Ver agenda completa
              </Button>
            </Paper>
            
            {/* AI Recommendations */}
            <SectionTitle
              title="Recomendações"
              subtitle="Sugestões personalizadas baseadas no seu perfil"
              icon={<LightbulbIcon />}
            />
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
              {recommendations.map((recommendation) => (
                <RecommendationCard 
                  key={recommendation.id} 
                  recommendation={recommendation} 
                />
              ))}
            </Box>
            
            {/* Quick Stats */}
            <Card sx={{ mb: 3, borderRadius: 3 }}>
              <CardContent>
                <SectionTitle
                  title="Estatísticas Rápidas"
                  icon={<TrendingUpIcon />}
                  divider={false}
                  spacing={1}
                />
                
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Materiais estudados
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      42
                    </Typography>
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Questões respondidas
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      537
                    </Typography>
                  </Box>
                  
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Taxa de aproveitamento
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      76%
                    </Typography>
                  </Box>
                </Box>
                
                <Button
                  variant="text"
                  fullWidth
                  sx={{ mt: 2 }}
                  endIcon={<ArrowForwardIcon />}
                >
                  Ver estatísticas detalhadas
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard; 