import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import RevokeEmail from './RevokeEmail';
import VerifyEmailAddress from './VerifyEmailAddress';

// Define accepted modes for Firebase authentication actions
const ACCEPTED_MODES = {
  resetPassword: 'resetPassword',
  recoverEmail: 'recoverEmail',
  verifyEmail: 'verifyEmail',
};

const Actions = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  // Get necessary parameters from URL
  const mode = queryParams.get('mode');
  const actionCode = queryParams.get('oobCode');
  const continueUrl = queryParams.get('continueUrl');
  const lang = queryParams.get('lang') || 'pt-BR';
  
  // Validate that we have the essential parameters
  if (!mode || !actionCode) {
    console.error('Missing required parameters in authentication action URL');
    return <Navigate to="/auth/login" replace />;
  }
  
  // Route to the appropriate component based on the mode
  switch (mode) {
    case ACCEPTED_MODES.resetPassword:
      return (
        <ResetPassword 
          actionCode={actionCode} 
          continueUrl={continueUrl} 
          lang={lang} 
        />
      );
      
    case ACCEPTED_MODES.recoverEmail:
      return (
        <RevokeEmail 
          actionCode={actionCode} 
          continueUrl={continueUrl} 
          lang={lang} 
        />
      );
      
    case ACCEPTED_MODES.verifyEmail:
      return (
        <VerifyEmailAddress 
          actionCode={actionCode} 
          continueUrl={continueUrl} 
          lang={lang} 
        />
      );
      
    default:
      console.error(`Unsupported action mode: ${mode}`);
      return <Navigate to="/auth/login" replace />;
  }
};

export default Actions; 