import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField,
  Typography, 
  Paper, 
  Container,
  CircularProgress,
  Alert
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../../components/common/Logo';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  const { forgotPassword } = useAuth();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Por favor, digite seu email.');
      return;
    }
    
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      await forgotPassword(email);
      setSuccess('Instruções de recuperação enviadas para seu email.');
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        setError('Email não encontrado. Verifique e tente novamente.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Email inválido. Por favor, verifique e tente novamente.');
      } else {
        setError('Falha ao enviar email de recuperação. Por favor, tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: 2,
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Logo />
          </Box>
          
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Recuperar senha
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Digite seu email para receber as instruções de recuperação
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ width: '100%', mb: 3 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ width: '100%', mb: 3 }}>
              {success}
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleForgotPassword} sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              sx={{ mb: 3 }}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ py: 1.5, mb: 3 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Enviar instruções'}
            </Button>
            
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                Lembrou sua senha?{' '}
                <Link to="/auth/login" style={{ textDecoration: 'none' }}>
                  <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                    Voltar para login
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPassword; 