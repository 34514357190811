import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField,
  Typography, 
  Paper, 
  Container,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Alert,
  Stack
} from '@mui/material';
import { Google as GoogleIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import AppleIcon from '@mui/icons-material/Apple';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../../components/common/Logo';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendingVerification, setResendingVerification] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState('');
  
  const navigate = useNavigate();
  const { login, loginWithGoogle, loginWithApple, verificationRequired, unverifiedEmail, resendVerificationEmail } = useAuth();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    
    // Basic frontend validation (can be enhanced with Yup/react-hook-form)
    if (!email.trim()) {
      setError('E-mail é obrigatório.');
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Formato de e-mail inválido.');
      return;
    }
    if (!password) {
      setError('Senha é obrigatória.');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      await login(email, password);
      // Navigation upon success is handled by AuthRedirector or context side effects
      // navigate('/dashboard'); // Removed potential redundant navigation
    } catch (err) {
      if (err.message === 'email-not-verified') {
        // Context handles setting verificationRequired state
        // Error message displayed by context/verification UI
      } else if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password' || err.code === 'auth/invalid-credential') {
        setError('Email ou senha incorretos. Por favor, tente novamente.');
      } else if (err.code === 'auth/too-many-requests') {
        setError('Muitas tentativas de login. Bloqueamos temporariamente esta conta. Tente redefinir sua senha ou tente novamente mais tarde.');
      } else {
        setError('Falha ao fazer login. Verifique sua conexão ou tente novamente.');
        console.error("Login Error:", err); // Log unexpected errors
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      // navigate('/dashboard'); // Handled by AuthRedirector
    } catch (err) {
      setError('Falha ao fazer login com Google. Por favor, tente novamente.');
      console.error("Google Login Error:", err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleAppleLogin = async () => {
    try {
      setError('');
      setLoading(true);
      await loginWithApple();
      // navigate('/dashboard'); // Handled by AuthRedirector
    } catch (err) {
      // Handle specific Apple errors if needed
      setError('Falha ao fazer login com Apple. Por favor, tente novamente.');
      console.error("Apple Login Error:", err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleResendVerification = async () => {
    try {
      setResendingVerification(true);
      setError('');
      setVerificationSent(false); // Reset verification sent status
      await resendVerificationEmail();
      setVerificationSent(true); // Set on success
    } catch (err) {
      setError('Falha ao reenviar email de verificação. Tente novamente mais tarde.');
      console.error("Resend Verification Error:", err);
      setVerificationSent(false);
    } finally {
      setResendingVerification(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // Prevent focus loss on click
  };

  // Email verification required UI
  if (verificationRequired) {
    return (
      <Container maxWidth="sm" data-testid="verify-email-container">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            py: 4,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              borderRadius: 2,
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Logo />
            </Box>
            
            <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }} data-testid="verify-email-title">
              Verifique seu e-mail
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3, textAlign: 'center' }} data-testid="verify-email-message">
              Enviamos um link de verificação para <strong data-testid="verify-email-address">{unverifiedEmail}</strong>. 
              Por favor, clique no link para ativar sua conta.
            </Typography>
            
            {verificationSent && (
              <Alert severity="success" sx={{ width: '100%', mb: 3 }} data-testid="verify-email-success-alert">
                Email de verificação reenviado com sucesso! Verifique sua caixa de entrada (e spam).
              </Alert>
            )}
            
            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 3 }} data-testid="verify-email-error-alert">
                {error}
              </Alert>
            )}
            
            <Button
              fullWidth
              variant="contained"
              onClick={handleResendVerification}
              disabled={resendingVerification}
              sx={{ py: 1.5, mb: 3 }}
              data-testid="verify-email-resend-button"
            >
              {resendingVerification ? <CircularProgress size={24} color="inherit" /> : 'Reenviar email de verificação'}
            </Button>
            
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                Já verificou?{' '}
                <Link 
                  to="/auth/login" 
                  style={{ textDecoration: 'none' }}
                  onClick={() => window.location.reload()} // Force reload to recheck auth state
                  data-testid="verify-email-back-to-login"
                >
                  <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                    Tente fazer login novamente
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }

  // Normal login UI
  return (
    <Container maxWidth="sm" data-testid="login-container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 4 }, // Responsive padding
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: 2,
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Logo />
          </Box>
          
          <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 600 }}>
            Bem-vindo de volta!
          </Typography>
          
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Entre com sua conta para continuar
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ width: '100%', mb: 3 }} data-testid="login-error-alert">
              {error}
            </Alert>
          )}
          
          <Box component="form" onSubmit={handleEmailLogin} sx={{ width: '100%' }} noValidate>
            {/* Added noValidate to rely on custom/library validation */}
            <TextField
              margin="normal"
              required // Keep required for semantics, but rely on JS validation
              fullWidth
              id="email" // Keep id for label association
              label="Email"
              name="email"
              type="email" // Use type="email" for better mobile keyboards
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              sx={{ mb: 2 }}
              data-testid="login-email-input" // Added test ID
              inputProps={{ 'data-testid': 'login-email-input-field' }} // Test ID for the actual input field if needed
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      data-testid="login-password-toggle" // Added test ID
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 1 }}
              data-testid="login-password-input" // Added test ID
              inputProps={{ 'data-testid': 'login-password-input-field' }} // Test ID for the actual input field if needed
            />
            
            <Box sx={{ textAlign: 'right', mb: 2 }}>
              <Link to="/auth/forgot-password" style={{ textDecoration: 'none' }} data-testid="login-forgot-password-link">
                <Typography variant="body2" color="primary">
                  Esqueceu a senha?
                </Typography>
              </Link>
            </Box>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ py: 1.5, mb: 2 }}
              disabled={loading}
              data-testid="login-submit-button" // Added test ID
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Entrar'}
            </Button>
            
            <Divider sx={{ my: 3 }}>ou</Divider>
            
            <Stack spacing={2}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleLogin}
                disabled={loading}
                sx={{ py: 1.5 }}
                data-testid="login-google-button" // Added test ID
              >
                Continuar com Google
              </Button>
              
              {/* Conditionally render Apple button based on platform if needed */}
              <Button
                fullWidth
                variant="outlined"
                startIcon={<AppleIcon />}
                onClick={handleAppleLogin}
                disabled={loading}
                sx={{ 
                  py: 1.5, 
                  mb: 3,
                  bgcolor: 'common.black', // Use theme colors
                  color: 'common.white',
                  borderColor: 'common.black',
                  '&:hover': {
                    bgcolor: 'grey.900', // Darker shade on hover
                    borderColor: 'grey.900',
                  }
                }}
                data-testid="login-apple-button" // Added test ID
              >
                Continuar com Apple
              </Button>
            </Stack>
            
            <Box sx={{ py: 1.5, textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                Não tem uma conta?{' '}
                <Link to="/auth/signup" style={{ textDecoration: 'none' }} data-testid="login-signup-link">
                  <Typography variant="body2" component="span" color="primary" sx={{ fontWeight: 500 }}>
                    Cadastre-se agora
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;